<template>
  <div class="cmsUniversityEdit">
    <div class="subject">
      <p class="title">登録大学 - {{ title }}</p>
    </div>
    <section>
      <div class="contentsWidth mx-auto mt-4">
        <b-form class="mt-4" autocomplete="off">

          <v-form-text
            itemStyle="mt-2"
            label="大学名"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）xxxx大学"
            :errMessage="errors.name || []"
            v-model="form.name"
            :isRequired="true"
          >
          </v-form-text>
          <hr>

          <v-form-text
            itemStyle="mt-2"
            label="部署等"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）国際交流部"
            :errMessage="errors.busyo || []"
            v-model="form.busyo"
            :isRequired="false"
          >
          </v-form-text>
          <hr>

          <v-form-text
            itemStyle="mt-2"
            label="郵便番号"
            maxLength="7"
            labelGridClass="inputLabel"
            placeholder="例）5401234"
            :errMessage="errors.postal_code || []"
            v-model="form.postal_code"
            :isRequired="false"
          >
          </v-form-text>
          <hr>

          <v-form-text
            itemStyle="mt-2"
            label="所在地"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）東京都○○区○○町1-2-3"
            :errMessage="errors.location || []"
            v-model="form.location"
            :isRequired="false"
          >
          </v-form-text>

          <hr>
          <v-form-text
            itemStyle="mt-2"
            label="電話番号"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）0363283030"
            :errMessage="errors.tel || []"
            v-model="form.tel"
            :isRequired="false"
          >
          </v-form-text>
          <hr>
          <div v-if="id" class="container-fluid itemWrap">
            <div class="row mt-2">
              <div class="preEmail">
                <label>現在のメールアドレス</label>
              </div>
              <div class="p-20 col-sm-8">
                <span>{{ currentEmail }}</span>
              </div>
            </div>
          </div>
          <b-form-checkbox
            v-if=id
            v-model="form.changeEmail"
          >
            メールアドレスを変更する
          </b-form-checkbox>
          <v-form-text
            itemStyle="mt-2"
            label="メールアドレス"
            maxLength="256"
            type="email"
            labelGridClass="inputLabel"
            placeholder="例）○○○○○○○○@abcd.co.jp"
            :isRequired="true"
            :class="!form.changeEmail && (id !== null) && 'grayscale'"
            :disabled="!form.changeEmail && (id !== null)"
            :errMessage="errors.mail_address || []"
            v-model="form.mail_address"
          >
          </v-form-text>
          <hr>

          <b-form-checkbox
            v-if="id"
            v-model="form.changePassword"
          >
            パスワードを変更する
          </b-form-checkbox>
          <v-form-text
            itemStyle="mt-2 attention"
            label="パスワード"
            maxLength="256"
            type="password"
            autocomplete="new-password"
            labelGridClass="inputLabel"
            placeholder=""
            :isRequired="true"
            @check = 'passCheck'
            :class="!form.changePassword && (id !== null) && 'grayscale'"
            :disabled="!form.changePassword && (id !== null)"
            :errMessage="errors.password || []"
            v-model="form.password"
          >
          </v-form-text>
          <v-form-text
            itemStyle="mt-2"
            label="パスワード確認"
            maxLength="256"
            type="password"
            autocomplete="new-password"
            labelGridClass="inputLabel"
            placeholder=""
            :isRequired="true"
            @check = 'passCheck'
            :class="!form.changePassword && (id !== null) && 'grayscale'"
            :disabled="!form.changePassword && (id !== null)"
            :errMessage="errors.password_twice || []"
            v-model="form.password_twice"
          >
          </v-form-text>
          <hr>

          <b-form-checkbox
            v-model="form.changeTerm"
          >
            ログイン可能期間を設定する
          </b-form-checkbox>
          <div class="calendarWrap">
            <v-form-calendar
              label="ログイン可能期間"
              input-class="pickerDateForm"
              labelClass="left"
              itemStyle="line"
              v-model="form.allowed_from"
              class="container-fluid itemWrap calendarWrap"
              :class="!form.changeTerm && 'grayscale'"
              :disabled="!form.changeTerm"
              :errMessage="errors.allowed_from || []"
              :isRequired="false"
              :hasBadge="true"
              :noMsg="true">
            </v-form-calendar>
            <v-form-calendar
              input-class="pickerDateForm"
              v-model="form.allowed_to"
              itemStyle="line"
              class="dismissal"
              :class="!form.changeTerm && 'grayscale'"
              :disabled="!form.changeTerm"
              :errMessage="errors.allowed_to || []"
              :noMsg="true">
            </v-form-calendar>
          </div>
          <div v-if="errors.allowed_from"
            class="error-term">
            {{errors.allowed_from[0]}}
          </div>
          <div v-if="errors.allowed_to"
            class="error-term">
            {{errors.allowed_to[0]}}
          </div>
          <hr>

          <b-container fluid class="itemWrap permission">
            <b-row class="mt-2">
              <div class="inputLabel align-items-start permission-label">
                <div class="badgeWrap"><div class="mr-2 badge badge-required">必須</div></div>
                <label class="bold">プログラム割当</label>
              </div>
              <div
                class="authList">
                <div
                  v-for="(series, key) in seriesList" :key="`series_${key}`">
                  <b-form-checkbox
                    v-model="form.series"
                    :value="series.value"
                    class="inlineBlock"
                    :state="state"
                    stacked
                  >
                    {{series.text}}
                  </b-form-checkbox>
                </div>
              </div>
            </b-row>
            <div v-if="errors['series.0'] || errors['series']" class="error-series">
              <p class="mb-0" v-if="errors['series.0']">{{errors['series.0'][0]}}</p>
              <p class="mb-0" v-if="errors['series']">{{errors['series'][0]}}</p>
            </div>
          </b-container>

        </b-form>
      </div>

      <div class="bottonArea mt-4 flex flexCenter">
        <b-button class="btn btn-lg mr-2 bold" to="/cms/university/list">一覧に戻る</b-button>
        <b-button
          @click="store"
          variant="info"
          class="btn btn save btn-primary bold"
        >{{ title }}</b-button>
        <b-button
          v-show="id"
          @click="destroy"
          class="btn btn-dark btn-lg ml-2 bold"
        >削除</b-button>
      </div>
    </section>

  </div>
</template>

<script>
import { Core as YubinBangoCore } from 'yubinbango-core';
import { cloneDeep } from 'lodash';
import api from '@/modules/api';

export default {
  name: 'CmsUniversityEdit',
  components: {
  },
  data() {
    return {
      title: '新規登録',
      id: null,
      currentEmail: '',
      errors: {},
      form: {
        name: '',
        busyo: '',
        changeEmail: false,
        mail_address: '',
        changePassword: false,
        password: '',
        password_twice: '',
        changeTerm: false,
        allowed_from: '',
        allowed_to: '',
        series: [],
        location: '',
        tel: '',
        postal_code: '',
      },
      rawSeriesList: [],
      isInitSeting: true,
    };
  },
  computed: {
    seriesList() {
      return this.rawSeriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
    },
    state() {
      return this.form.series.length > 0;
    },
  },
  methods: {
    init() {
      this.errors = {};
      this.form.name = '';
      this.form.busyo = '';
      this.form.changeEmail = false;
      this.form.mail_address = '';
      this.form.changePassword = false;
      this.form.password = '';
      this.form.password_twice = '';
      this.form.changeTerm = false;
      this.form.allowed_from = '';
      this.form.allowed_to = '';
      this.form.series = [];
      this.form.location = '';
      this.form.tel = '';
      this.form.postal_code = '';
      this.isInitSeting = true;
    },
    // ユーザーNoがある場合は更新画面
    async initFetch() {
      this.init();
      const params = { id: this.id };
      const response = await api.send('/api/cms/university/edit', params)
        .catch(async () => {
          await this.alert('ユーザーのデータを取得できませんでした。', false);
          window.location.assign('/cms/university/list');
        });
      this.isInitSeting = true;
      // idがあるのに取得データが空だとエラー
      if (this.id && !response.data.edit) {
        await this.alert('ユーザーのデータを取得できませんでした。', false);
        window.location.assign('/cms/university/list');
      }
      this.rawSeriesList = response.data.seriesList;
      if (response.data.edit) {
        this.id = response.data.edit.id;
        this.currentEmail = response.data.edit.login_id;
        this.form.name = response.data.edit.university.university_name;
        this.form.busyo = response.data.edit.university.busyo;
        this.form.mail_address = response.data.edit.mail_address;
        this.form.location = response.data.edit.university.location;
        this.form.tel = response.data.edit.university.tel;
        this.form.postal_code = response.data.edit.university.postal_code;

        this.form.allowed_from = response.data.edit.allowed_from;
        this.form.allowed_to = response.data.edit.allowed_to;
        if (this.form.allowed_from || this.form.allowed_to) {
          this.form.changeTerm = true;
        }

        this.form.series = response.data.edit.university_series.map((series) => {
          return series.series_id;
        });
      }
      if (this.form.postal_code === '') {
        this.isInitSeting = false;
      }
    },
    // 登録更新
    async store() {
      this.$store.dispatch('page/onWaiting');
      this.errors = {};
      const params = cloneDeep(this.form);
      params.id = this.id;
      const res = await api
        .send('/api/cms/university/store', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          this.$store.dispatch('page/offWaiting');
          return false;
        });
      if (res !== false) {
        await this.alert('登録が完了しました。', false);
        this.initFetch();
      }
      this.$store.dispatch('page/offWaiting');
    },
    // 削除
    async destroy() {
      if (!await this.confirm('大学ユーザーを削除しますか？', false)) return;
      const param = { id: this.id };
      this.$store.dispatch('page/onWaiting');
      const del = await api.send('/api/cms/university/destroy', param)
        .catch(async (error) => {
          await this.alert(error.response.data.message, false);
        });
      if (del !== false) {
        await this.alert('削除が完了しました。', false);
        window.location.assign('/cms/university/list');
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.errors.password_twice = [];
      if (this.form.password !== this.form.password_twice) {
        this.errors.password_twice = ['パスワードの確認とパスワードが一致するよう入力してください。'];
      }
    },
  },
  // ロード画面
  created() {
    this.$store.dispatch('page/onLoading');
    this.id = this.$route.params.userId || null;
    if (this.id) {
      this.title = '編集';
    }
    this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
  watch: {
    'form.postal_code': {
      handler(newPostalCode) {
        if (this.isInitSeting) {
          this.isInitSeting = false;
          return;
        }
        if (newPostalCode.length < 7) {
          return;
        }
        let address = '';
        // eslint-disable-next-line
        new YubinBangoCore(newPostalCode, (addr) => {
          address = addr.region + addr.locality + addr.street;
          if (address === '') {
            return;
          }
          this.form.location = address;
        });
      },
      deep: true,
    },
  },
};
</script>

<style>
  .cmsUniversityEdit .contentsWidth {
    background: #FFF;
    padding: 2rem;
    width: 1080px;
  }
  .cmsUniversityEdit .title {
    margin-bottom: 0;
  }
  .cmsUniversityEdit .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }
  .cmsUniversityEdit input {
    height: 50px;
    font-size: 16px;
  }
  .cmsUniversityEdit .inputLabel {
    justify-content: flex-start;
  }
  .cmsUniversityEdit form .row {
    margin: 0;
  }
  .cmsUniversityEdit .itemWrap {
    margin: 15px 0;
    width: 100%;
  }
  .cmsUniversityEdit .itemWrap .textWrap {
    padding-left: 15px;
  }
  .cmsUniversityEdit .preEmail {
    width: 300px;
    font-weight: bold;
    white-space: pre-line;
    padding-left: 60px;
  }
  .permission-label {
    margin: 0 0 0 15px;
    width: 300px;
  }
  .cmsUniversityEdit .preEmail + div {
    word-break: break-word;
  }
  .cmsUniversityEdit .grayscale input {
    background: #F3F3F3;
    color: #F3F3F3;
  }
  .cmsUniversityEdit .grayscale {
    color: #3333334d
  }
  .cmsUniversityEdit .vdp-datepicker {
    width: 220px;
    padding-left: 15px;
  }
  .cmsUniversityEdit .dismissal {
    position: absolute;
    top: -0.6rem;
    left: 32rem;
  }
  .cmsUniversityEdit .dismissal::before {
    content: "～";
    float: left;
    margin-top: 1.3rem;
    margin-left: 40px;
  }
  .cmsUniversityEdit .calendarWrap {
    position: relative;
  }
  .cmsUniversityEdit .calendarWrap label {
    font-weight: 600;
  }
  .cmsUniversityEdit .calendarWrap.errItem {
    margin-bottom: 50px;
  }
  .cmsUniversityEdit .calendarWrap .error {
    position: absolute;
  }
  .cmsUniversityEdit .errItem + .dismissal {
    top: -0.6rem;
    left: 28rem;
  }
  .cmsUniversityEdit .dismissal .right {
    display: none;
  }
  .cmsUniversityEdit input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    min-height: 1.5rem;
    margin-left: 329px;
  }
  .cmsUniversityEdit .permission label.bold {
    margin-top: 2px;
  }
  .cmsUniversityEdit .permission input[type="checkbox"] + label {
    margin-left: 0;
  }
  .cmsUniversityEdit .attention::before {
    content: '英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。';
    font-size: 13px;
    margin-left: 315px;
    width: 524px;
  }
  .cmsUniversityEdit .error {
    margin-left: 15px;
    padding-left: 0;
  }
  .cmsUniversityEdit .errItem input.form-control {
    border: solid 1px #dc3545 !important
  }
  .cmsUniversityEdit .custom-control-input.is-invalid ~ .custom-control-label,
  .cmsUniversityEdit .custom-control-input.is-valid ~ .custom-control-label {
    color: #333;
  }
  .university-select-wrap {
    width: 200px;
    margin-left: 10px;
  }
  .university-select-wrap>select {
    height: 40px;
  }
</style>

<style scoped>
  .error-term {
    font-size: 12.8px;
    color: #dc3545;
    display: block;
    width: 400px;
    margin-top: 3px;
    margin-left: 350px;
  }
  .error-series {
    font-size: 12.8px;
    color: #dc3545;
    display: block;
    width: 400px;
    margin-top: 3px;
    margin-left: 335px;
  }
</style>
